var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pvhFactory" },
    [
      _c(
        "basic-container",
        [
          _c("div", { staticClass: "layTop" }, [
            _c(
              "div",
              {
                staticClass: "Title",
                staticStyle: { "margin-bottom": "20px" }
              },
              [
                _vm._v(
                  _vm._s(_vm.$t("Added quality feedback")) + "\n            "
                )
              ]
            )
          ]),
          [
            _c(
              "div",
              [
                _c(
                  "el-form",
                  {
                    ref: "refForm",
                    attrs: {
                      model: _vm.formObj,
                      rules: _vm.formObjRules,
                      "label-position": "top"
                    }
                  },
                  [
                    _c(
                      "el-divider",
                      { attrs: { "content-position": "left" } },
                      [_vm._v(_vm._s(_vm.$t("feedbackStandard")))]
                    ),
                    _c(
                      "el-row",
                      { staticClass: "el-row_style" },
                      [
                        _c(
                          "el-col",
                          {
                            staticClass: "el-col_style",
                            attrs: { span: 12, sm: 24, md: 12 }
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("Quality standard number"),
                                  prop: "code",
                                  "label-width": "120px",
                                  rules: _vm.formObjRules.code
                                }
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "calc(100% - 115px)" },
                                  attrs: {
                                    filterable: "",
                                    clearable: "",
                                    placeholder: ""
                                  },
                                  model: {
                                    value: _vm.formObj.code,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formObj, "code", $$v)
                                    },
                                    expression: "formObj.code"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticClass: "el-col_style",
                            attrs: { span: 12, sm: 24, md: 12 }
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("Name of quality standard"),
                                  prop: "name",
                                  "label-width": "120px",
                                  rules: _vm.formObjRules.name
                                }
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "calc(100% - 115px)" },
                                  attrs: {
                                    filterable: "",
                                    clearable: "",
                                    placeholder: ""
                                  },
                                  model: {
                                    value: _vm.formObj.name,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formObj, "name", $$v)
                                    },
                                    expression: "formObj.name"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("div", { staticClass: "smallTit" }, [
                      _vm._v(_vm._s(_vm.$t("Parameter")))
                    ]),
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.formObj.itemTable,
                          "row-class-name": _vm.tableRowClassName,
                          height: "420",
                          "header-cell-class-name": _vm.starAdd
                        }
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("Index"),
                            type: "index",
                            width: "50px",
                            align: "center"
                          }
                        }),
                        false
                          ? _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("standard number"),
                                align: "center"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop:
                                                "itemTable." +
                                                scope.$index +
                                                ".standardCode"
                                            }
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: { clearable: "" },
                                              model: {
                                                value: scope.row.standardCode,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "standardCode",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.standardCode"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1055754836
                              )
                            })
                          : _vm._e(),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("parameter number"),
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "itemTable." +
                                          scope.$index +
                                          ".parameterCode"
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          filterable: "",
                                          clearable: "",
                                          placeholder: "",
                                          disabled: true
                                        },
                                        model: {
                                          value: scope.row.parameterCode,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "parameterCode",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.parameterCode"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("parameter name"),
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "itemTable." +
                                          scope.$index +
                                          ".parameterName"
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          filterable: "",
                                          clearable: "",
                                          placeholder: "",
                                          disabled: true
                                        },
                                        model: {
                                          value: scope.row.parameterName,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "parameterName",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.parameterName"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        false
                          ? _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("parameter type"),
                                align: "center"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop:
                                                "itemTable." +
                                                scope.$index +
                                                ".type"
                                            }
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                clearable: "",
                                                placeholder: ""
                                              },
                                              model: {
                                                value: scope.row.type,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "type",
                                                    $$v
                                                  )
                                                },
                                                expression: "scope.row.type"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                3620180001
                              )
                            })
                          : _vm._e(),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("standard description"),
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "itemTable." +
                                          scope.$index +
                                          ".specificStandards"
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          clearable: "",
                                          disabled: scope.row.type !== 0
                                        },
                                        model: {
                                          value: scope.row.specificStandards,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "specificStandards",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "scope.row.specificStandards"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { label: _vm.$t("MAXIMUM"), align: "center" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "itemTable." +
                                          scope.$index +
                                          ".maxValue",
                                        rules: _vm.formObjRules.maxValue
                                      }
                                    },
                                    [
                                      _c("el-input-number", {
                                        attrs: {
                                          clearable: "",
                                          min: 0,
                                          disabled: scope.row.type !== 1
                                        },
                                        model: {
                                          value: scope.row.maxValue,
                                          callback: function($$v) {
                                            _vm.$set(scope.row, "maxValue", $$v)
                                          },
                                          expression: "scope.row.maxValue"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { label: _vm.$t("MINIMUM"), align: "center" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "itemTable." +
                                          scope.$index +
                                          ".minValue"
                                      }
                                    },
                                    [
                                      _c("el-input-number", {
                                        attrs: {
                                          clearable: "",
                                          min: 0,
                                          disabled: scope.row.type !== 1
                                        },
                                        model: {
                                          value: scope.row.minValue,
                                          callback: function($$v) {
                                            _vm.$set(scope.row, "minValue", $$v)
                                          },
                                          expression: "scope.row.minValue"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "text-align": "right",
                          padding: "50px 0"
                        }
                      },
                      [
                        this.routeSign !== "update"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.submitForm }
                              },
                              [_vm._v(_vm._s(_vm.$t("Save")))]
                            )
                          : _vm._e(),
                        _c("el-button", { on: { click: _vm.resetForm } }, [
                          _vm._v(_vm._s(_vm.$t("Cancel")))
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }